<template>
    <v-container :class="$vuetify.breakpoint.mobile ? 'ma-0 pa-0 mx-auto' : null">
        <v-row>
            <v-col cols="12">
                <v-toolbar v-if="!specificStep" color="secondary">
                    <BT-Btn
                        v-if="settings != null && settings.data.currentIndex > 0"
                        @click="back"
                        dark
                        inline
                        small
                        label="Back"
                        text />
                    <v-progress-linear 
                        v-if="settings != null"
                        :value="(settings.data.currentIndex / settings.data.stepperPlan.length) * 100"
                        color="white" />
                    <BT-Btn
                        @click="restart"
                        dark
                        icon="mdi-restart"
                        inline
                        small
                        title="Restart whole setup process again." />
                </v-toolbar>

                <Setup-Step
                    @next="f => f()"
                    icon="mdi-human-greeting"
                    :settings="settings"
                    showNext
                    step="start"
                    title="Welcome!">
                    <template v-slot:body="{ next }">
                        <Setup-Block>
                            <p>Let's help you get set up!  Just a few questions so we know how to organize your settings.</p>
                        
                            <p>(Don't worry!  Everything can be changed later on if need be.)</p>

                            <div class="my-4 d-flex align-center">
                                <v-divider />
                                <h3 class="mx-4"> OR </h3>
                                <v-divider />
                            </div>

                            <p>Speed up the process by using BlitzIt Web's recommended settings.</p>

                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-card rounded hover @click="recommended(next)">
                                        <v-img height="200" src="https://blitzittechimages.blob.core.windows.net/blitzit/small-business-setup.jpg" ></v-img>
                                        <v-card-title>Basic Supplier Logistics</v-card-title>
                                        <v-card-text>
                                            Recommended for small businesses that supply and deliver from one location.
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-card rounded hover @click="next">
                                        <v-img height="200" src="https://blitzittechimages.blob.core.windows.net/blitzit/medium-supplier-setup.jpg" ></v-img>
                                        <v-card-title>Advanced Logistics</v-card-title>
                                        <v-card-text>
                                            Quite a few more options and information to choose from when setting up.
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </Setup-Block>
                    </template>
                </Setup-Step>

                <Location-Setup :settings="settings" />
                
                <Setup-Step
                    @next="nextOrdering"
                    :settings="settings"
                    showNext
                    step="ordering-questions"
                    title="Ordering">
                    <template v-slot:body>
                        <p>Do you want to use BlitzIt Web to manage customers' orders?</p>

                        <BT-List-Item-Check
                            @true="settings.data.orderingAndFulfilling = false"
                            class="my-3"
                            subtitle="If you want to manage customer orders and invoicing but nothing to do with stock management and dispatch."
                            title="Online Ordering"
                            v-model="settings.data.orderingAndInvoicingOnly" />
                        
                        <BT-List-Item-Check
                            @true="settings.data.orderingAndInvoicingOnly = false"
                            class="my-3"
                            subtitle="If you are a supplier selling products to customers and want to use this system to manage the stocking, picking, packing, and delivery of those orders."
                            title="Online Ordering & Order Fulfillment"
                            v-model="settings.data.orderingAndFulfilling" />
                    </template>
                </Setup-Step>

                <Setup-Step
                    @next="nextFulfilling"
                    :settings="settings"
                    showNext
                    step="order-fulfilling-questions"
                    title="Order Fulfilling">
                    <template v-slot:body>
                        <p>How do you manage fulfillment of customer orders?</p>

                        <BT-List-Item-Check
                            class="my-3"
                            :disabled="!settings.data.orderingAndFulfilling"
                            subtitle="If you are a supplier who manages the stocking, picking, packing, and dispatch of your customers' orders."
                            title="We Fulfill Our Customer Orders Ourselves"
                            v-model="settings.data.orderFulfillingForSelf" />

                        <BT-List-Item-Check
                            class="my-3"
                            subtitle="If you are a 3PL service who manages the stocking, picking, packing, and dispatch of other suppliers' customer orders."
                            title="We Fulfill Orders For Other Suppliers"
                            v-model="settings.data.orderFulfillingForOthers" />
                        
                        <BT-List-Item-Check
                            class="my-3"
                            :disabled="!settings.data.orderingAndFulfilling"
                            subtitle="If you are a supplier who outsources the stocking, picking, packing, and dispatch of your customer orders to other third party service."
                            title="We Use Third Parties To Fulfill Our Orders"
                            v-model="settings.data.orderFulfillingByOthers" />
                    </template>
                </Setup-Step>
                
                <Setup-Step
                    @next="nextCouriering"
                    :settings="settings"
                    showNext
                    step="couriering-questions"
                    title="Couriering">
                    <template v-slot:body>
                        <p>Do you want to use BlitzIt Web to manage couriering and deliveries?</p>

                        <BT-List-Item-Check
                            @true="pickupOnly"
                            class="my-3"
                            :disabled="!(settings.data.orderFulfillingForSelf || settings.data.orderFulfillingForOthers)"
                            subtitle="If you are a supplier or 3PL service that only allows customers to pickup from your locations."
                            title="We Only Allow Pickup"
                            v-model="settings.data.pickupOnly" />

                        <BT-List-Item-Check
                            @true="pickupOnly = false"
                            class="my-3"
                            :disabled="!(settings.data.orderFulfillingForSelf || settings.data.orderFulfillingForOthers)"
                            subtitle="If you do your own deliveries."
                            title="We Deliver To Our Customers"
                            v-model="settings.data.courieringForSelf" />

                        <BT-List-Item-Check
                            @true="pickupOnly = false"
                            class="my-3"
                            subtitle="If you do deliveries for other companies."
                            title="We Deliver For Other Suppliers"
                            v-model="settings.data.courieringForOthers" />

                        <BT-List-Item-Check
                            @true="pickupOnly = false"
                            class="my-3"
                            :disabled="!(settings.data.orderFulfillingForSelf || settings.data.orderFulfillingForOthers)"
                            subtitle="If you pay other couriers to do deliveries for you."
                            title="We Use Third Parties To Do Deliveries"
                            v-model="settings.data.courieringByOthers" />

                    </template>
                </Setup-Step>

                <Product-Setup :settings="settings" />

                <Product-Category-Setup :settings="settings" />

                <Supplier-Price-Tier-Setup :settings="settings" />

                <Supplier-Payment-Terms-Setup :settings="settings" />

                <Setup-Step
                    @next="f => f()"
                    :settings="settings"
                    showNext
                    step="order-numbering"
                    title="Order Numbering"
                    text="What was your last customer order number?">
                    <template v-slot:body>
                        <p>The customer order number is assigned to every new order and will automatically increment higher for each new order.</p>
                        <p>What was your last customer order number?</p>
                        <div>
                            <BT-Entity
                                ignoreID
                                navigation="supplier-settings"
                                single>
                                <template v-slot="{ item, save }">
                                    <BT-Square-Number
                                        @change="save"
                                        v-model.number="item.lastCustomerOrderNumber"
                                        isEditing
                                        label="Last Customer Order Number"
                                        type="number" />
                                </template>
                            </BT-Entity>
                        </div>
                    </template>
                </Setup-Step>

                <Setup-Step
                    @yes="f => updateOnlineNext(f, 'supplier-settings', { invoiceNumberTrigger: 'OrderNumber' })"
                    @no="f => updateOnlineNext(f, 'supplier-settings', { invoiceNumberTrigger: 'Auto' }, ['invoice-numbering'])"
                    :settings="settings"
                    showSkip
                    showYesNo
                    step="invoice-numbering-matches-orders"
                    title="Invoice Numbering">
                    <template v-slot:body>
                        <p>Do you want your invoice numbers to be the same as your customer order numbers?</p>

                        <Setup-Info>
                            <div class="text-subtitle-2">Customer Order Number</div>
                            <p class="text-body-2">An order is assigned a customer order number by the supplier when it is created.</p>

                            <div class="text-subtitle-2">Purchase Order Number</div>
                            <p class="text-body-2">An order can be give a purchase order number by the customer at any time.</p>

                            <div class="text-subtitle-2">Invoice Number</div>
                            <div class="text-body-2">When an order is fulfilled an invoice is issued and an invoice number is assigned to the invoice.  This number can be brand new or it can be identical to the customer order number.</div>
                        </Setup-Info>

                        <!-- <Setup-Example>
                            A customer order is created with #55.  Once this order is fulfilled and invoiced, the invoice will have an invoice number of #55.  If the order is deleted without being invoiced, the number #55 will be discarded.
                        </Setup-Example> -->
                    </template>
                </Setup-Step>

                <Setup-Step
                    @next="f => f()"
                    :settings="settings"
                    showNext
                    step="invoice-numbering"
                    title="Invoice Numbering">
                    <template v-slot:body>
                        <p>What was your last invoice number?</p>
                        <div>
                            <BT-Entity
                                ignoreID
                                navigation="supplier-settings"
                                single>
                                <template v-slot="{ item, save }">
                                    <BT-Square-Number
                                        @change="save"
                                        v-model.number="item.lastInvoiceNumber"
                                        isEditing
                                        label="Last Invoice Number"
                                        type="number" />
                                </template>
                            </BT-Entity>
                        </div>
                    </template>
                </Setup-Step>

                <Setup-Step
                    @yes="f => updateOnlineNext(f, 'supplier-settings', { emailConfirmedOrders: true }, ['email-new-orders-to-supplier'])"
                    @no="f => updateOnlineNext(f, 'supplier-settings', { emailConfirmedOrders: false })"
                    :settings="settings"
                    showSkip
                    showYesNo
                    step="email-new-orders-to-customer"
                    title="Emailing New Orders To Customers"
                    text="Would you like an email to be sent to the customer when their orders are approved?" />

                <Setup-Step
                    @yes="f => next(f, ['supplier-email-addresses'])"
                    @no="f => updateOnlineNext(f, 'supplier-settings', { emailAddressesAsSupplier: null })"
                    :settings="settings"
                    showSkip
                    showYesNo
                    step="email-new-orders-to-supplier"
                    title="Emailing New Orders To Yourself"
                    text="Would you like an email to be sent to yourself when a new order is created?" />

                <Setup-Step
                    @next="f => f()"
                    :settings="settings"
                    showNext
                    step="supplier-email-addresses"
                    title="Email Address">
                    <template v-slot:body>
                        <p>What email address would you like new orders to be sent to?</p>
                        <div>
                            <BT-Entity
                                ignoreID
                                navigation="supplier-settings"
                                single>
                                <template v-slot="{ item, save }">
                                    <BT-Tags
                                        @change="save"
                                        key="26"
                                        label="Email Address"
                                        v-model="item.emailAddressesAsSupplier" />
                                </template>
                            </BT-Entity>
                        </div>
                    </template>
                </Setup-Step>

                <Delivery-Fee-Setup :settings="settings" />
                
                <Setup-Step
                    @yes="f => next(f, ['order-slots'])"
                    @no="next"
                    :settings="settings"
                    showSkip
                    showYesNo
                    step="ordering-style"
                    title="Slotted Ordering Pattern?">
                    <template v-slot:body>
                        <Setup-More :settings="settings">
                            <Setup-Info>
                                <p>
                                    BlitzIt Web offers two options for order timing management: Open Ordering or Slotted Ordering.
                                    Open Ordering allows the customer to place an order for delivery on any day after a specified amount of lead time.
                                    Slotted Ordering limits the customer to place an order for delivery only days that you choose and only if placed before a specified cut-off time.
                                </p>
                                <p>
                                    This can be defined for each customer individually.
                                </p>
                            </Setup-Info>

                            <Setup-Example>
                                <div class="text-subtitle-2">Slotted Ordering</div>
                                <p>Creative Crayons LTD only deliver to their customers on Tuesdays and Thursdays.  They also require that for an order to be delivered on Thursday it needs to be placed by Tuesday.</p>
                            </Setup-Example>

                            <Setup-Example>
                                <div class="text-subtitle-2">Open Ordering</div>
                                <p>Buttercup Butter deliver every day but require 24 hours of lead time.</p>
                            </Setup-Example>
                        </Setup-More>

                        <p class="mt-4">Do you limit when your customers can get deliveries and when they need to order by?</p>
                    </template>
                </Setup-Step>

                <Order-Slot-Setup :settings="settings" />

                <Product-Group-Setup :settings="settings" />

                <Ordering-Rules-Group-Setup :settings="settings" />
                
                <Setup-Step
                    @next="f => f()"
                    fluid
                    :settings="settings"
                    showNext
                    step="order-pipeline"
                    title="Order Pipeline">
                    <template v-slot:body>

                        <v-divider class="my-4" />

                        <BT-Order-Cycle-Header showTrimmings />

                        <v-divider class="my-4" />

                        <Setup-Block>
                            <p class="text-body2">Orders go through a processing pipeline.  Each section in this pipeline allows you to control what happens to the order with greater flexibility.  You can automate everything from start to end or you can require user input at certain stages of the process.</p>
                        </Setup-Block>

                    </template>
                </Setup-Step>

                <Order-Approval-Setup :settings="settings" />

                <Order-Fulfill-Setup :settings="settings" />

                <Fulfiller-Setup :settings="settings" />
                
                <Fulfiller-Pointer-Setup :settings="settings" />

                <Order-Process-Setup :settings="settings" />

                <Order-Stock-Consign-Setup :settings="settings" />

                <Supply-Pointer-Setup :settings="settings" />

                <Order-Package-Consign-Setup :settings="settings" @next="nextPackageConsign" />

                <Measurement-Setup :settings="settings" />

                <Setup-Step
                    @next="f => f()"
                    :settings="settings"
                    showNext
                    step="select-consignment-packages"
                    title="Consignment Packages">
                    <template v-slot:body>
                        <BT-Entity
                            ignoreID
                            inline
                            navigation="picking-settings"
                            single>
                            <template v-slot="{ item, save }">

                                <p>What packages would you like BlitzIt Web to use to calculate how many packages are in a consignment?</p>

                                <v-slide-x-transition>
                                    <BT-Select-List
                                        v-if="item.calculateConsignmentPackages"
                                        @change="save"
                                        class="mt-4"
                                        v-model="item.consignmentPackageIDs"
                                        chips
                                        height="60vh"
                                        itemText="measurementName"
                                        itemValue="id"
                                        multiple
                                        returnCSV
                                        outlined
                                        navigation="measurements" />
                                </v-slide-x-transition>

                            </template>
                        </BT-Entity>
                    </template>
                </Setup-Step>

                <Order-Ready-For-Courier-Setup :settings="settings" />

                <Order-Request-Courier-Setup :settings="settings" />

                <Courier-Setup :settings="settings" />

                <Courier-Pointer-Setup :settings="settings" />
                
                <Customer-Template-Setup :settings="settings" />

                <Customer-Setup :settings="settings" />
                
                <Setup-Step
                    @yes="f => next(f, ['courier-payment-terms', 'courier-pricing', 'zones'])"
                    @no="f => f()"
                    :settings="settings"
                    showYesNo
                    step="courier-pricing-question"
                    title="Courier Pricing">
                    <template v-slot:body>
                        <p>Do you want to configure pricing for couriering?</p>
                    </template>
                </Setup-Step>

                <Zone-Setup :settings="settings" />

                <Courier-Price-Tier-Setup :settings="settings" />

                <Courier-Payment-Terms-Setup :settings="settings" />
                
                <Setup-Step
                    @next="f => f()"
                    fluid
                    :settings="settings"
                    showNext
                    step="courier-pipeline"
                    title="Courier Order Pipeline">
                    <template v-slot:body>

                        <v-divider class="my-4" />

                        <BT-Courier-Cycle-Header showTrimmings />

                        <v-divider class="my-4" />

                        <Setup-Block>
                            <p class="text-body2">Courier orders go through a processing pipeline.  Each section in this pipeline allows you to control what happens to the order with greater flexibility.  You can automate everything from start to end or you can require user input at certain stages of the process.</p>
                        </Setup-Block>

                    </template>
                </Setup-Step>

                <Courier-Order-Approval-Setup :settings="settings" />

                <Courier-Order-Plan-Setup :settings="settings" />

                <Route-Setup :settings="settings" />

                <!--route pointers-->

                <Courier-Order-Move-Setup :settings="settings" />

                <Courier-Order-Assign-Setup :settings="settings" />

                <Journey-Setup :settings="settings" />

<!-- 
                <Setup-Step
                    fluid
                    showSkip
                    step="self-courier-approve">
                    <template v-slot:body>
                        <BT-Order-Cycle-Header showTrimmings highlightApprove title="Courier Pipeline: Approve Requests" />

                        <v-divider class="my-4" />

                        <Setup-Block>
                            <BT-
                        </Setup-Block>
                    </template>
                </Setup-Step> -->

                <Driver-Setup :settings="settings" />
            </v-col>
        </v-row>
        <v-overlay :value="loadingMsg != null" absolute class="text-center">
            <v-progress-circular indeterminate size="64" />
            <p>{{ loadingMsg }}</p>
        </v-overlay>
    </v-container>
</template>

<script>
export default {
    name: 'Setup',
    components: {
        BTListItemCheck: () => import('~components/BT-List-Item-Check.vue'),
        BTCourierCycleHeader: () => import('~components/BT-Courier-Cycle-Header.vue'),
        BTOrderCycleHeader: () => import('~components/BT-Order-Cycle-Header.vue'),
        CourierPaymentTermsSetup: () => import('~home/payment-terms/Courier-Payment-Terms-Setup.vue'),
        CourierSetup: () => import('~home/couriers/Courier-Setup.vue'),
        CourierOrderApprovalSetup: () => import('~home/setup/Courier-Order-Approval-Setup.vue'),
        CourierOrderAssignSetup: () => import('~home/setup/Courier-Order-Assign-Setup.vue'),
        CourierOrderMoveSetup: () => import('~home/setup/Courier-Order-Move-Setup.vue'),
        CourierOrderPlanSetup: () => import('~home/setup/Courier-Order-Plan-Setup.vue'),
        CourierPointerSetup: () => import('~home/courier-pointers/Courier-Pointer-Setup.vue'),
        CourierPriceTierSetup: () => import('~home/price-tiers/Courier-Price-Tier-Setup.vue'),
        CustomerTemplateSetup: () => import('~home/customer-templates/Customer-Template-Setup.vue'),
        CustomerSetup: () => import('~home/customers/Customer-Setup.vue'),
        DeliveryFeeSetup: () => import('~home/delivery-fees/Delivery-Fee-Setup.vue'),
        DriverSetup: () => import('~home/users/Driver-Setup.vue'),
        FulfillerSetup: () => import('~home/fulfillers/Fulfiller-Setup.vue'),
        FulfillerPointerSetup: () => import('~home/fulfiller-pointers/Fulfiller-Pointer-Setup.vue'),
        JourneySetup: () => import('~home/journeys/Journey-Setup.vue'),
        LocationSetup: () => import('~home/locations/Location-Setup.vue'),
        MeasurementSetup: () => import('~home/measurements/Measurement-Setup.vue'),
        OrderApprovalSetup: () => import('~home/setup/Order-Approval-Setup.vue'),
        OrderStockConsignSetup: () => import('~home/setup/Order-Stock-Consign-Setup.vue'),
        OrderFulfillSetup: () => import('~home/setup/Order-Fulfill-Setup.vue'),
        OrderPackageConsignSetup: () => import('~home/setup/Order-Package-Consign-Setup.vue'),
        OrderProcessSetup: () => import('~home/setup/Order-Process-Setup.vue'),
        OrderReadyForCourierSetup: () => import('~home/setup/Order-Ready-For-Courier-Setup.vue'),
        OrderRequestCourierSetup: () => import('~home/setup/Order-Request-Courier-Setup.vue'),
        OrderingRulesGroupSetup: () => import('~home/order-rules/Ordering-Rules-Group-Setup.vue'),
        OrderSlotSetup: () => import('~home/order-slots/Order-Slot-Setup.vue'),
        ProductSetup: () => import('~home/products/Product-Setup.vue'),
        ProductCategorySetup: () => import('~home/product-categories/Product-Category-Setup.vue'),
        ProductGroupSetup: () => import('~home/product-groups/Product-Group-Setup.vue'),
        RouteSetup: () => import('~home/route-pointers/Route-Setup.vue'),
        SupplierPaymentTermsSetup: () => import('~home/payment-terms/Supplier-Payment-Terms-Setup.vue'),
        SupplierPriceTierSetup: () => import('~home/price-tiers/Supplier-Price-Tier-Setup.vue'),
        SupplyPointerSetup: () => import('~home/supply-pointers/Supply-Pointer-Setup.vue'),
        SetupBlock: () => import('~home/setup/Setup-Block.vue'),
        SetupExample: () => import('~home/setup/Setup-Example.vue'),
        SetupInfo: () => import('~home/setup/Setup-Info.vue'),
        SetupMore: () => import('~home/setup/Setup-More.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue'),
        ZoneSetup: () => import('~home/zones/Zone-Setup.vue')
    },
    data() {
        return {
            loadingMsg: null,
            settings: null,
            specificStep: false
        }
    },
    mounted() {
        var settingsData = this.getLocally(this.dataKey);
        var settingsHistory = this.getLocally(this.historyKey);

        this.settings = {
            data: settingsData || {
                currentIndex: 0,
                stepperPlan: ['start', 'locations', 'ordering-questions'],
                currentStep: 'start',

                orderingAndInvoicingOnly: false,
                orderingAndFulfilling: false,

                orderFulfillingForSelf: false,
                orderFulfillingForOthers: false,
                orderFulfillingByOthers: false,

                pickupOnly: false,
                courieringForSelf: false,
                courieringForOthers: false,
                courieringByOthers: false,

                showMore: false,
                stockManaging: false
            },
            history: settingsHistory || []
        };

        var ind = this.settings.data.currentIndex;

        if (this.$route.params && this.$route.params.setup != null) {
            ind = Number.parseInt(this.$route.params.setup);
        }
        else if (this.$route.query && this.$route.query.setup != null) {
            ind = Number.parseInt(this.$route.query.setup);
        }
        else if (this.$route.query && this.$route.query.step != null) {
            this.specificStep = true;
            ind = 0;
            this.settings.data.stepperPlan = [this.$route.query.step];
            this.settings.data.currentStep = this.$route.query.step;
        }
        else {
            //ind = 0;
            //this.restart(false);
        }

        this.settings.data.currentIndex = ind;
        this.settings.data.currentStep = this.settings.data.stepperPlan[ind];
    },
    computed: {
        dataKey() {
            return `${this.$BlitzIt.auth.session.data?.companyAccountID}-setup-data`;
        },
        historyKey() {
            return `${this.$BlitzIt.auth.session.data?.companyAccountID}-setup-history`;
        }
    },
    methods: {
        recommended(nextFunc) {
            this.settings.data.stepperPlan = ['start'];

            //create recommended plan
            this.insertLast([
                'products',
                'product-categories',
                'supplier-pricing',
                'supplier-payment-terms',
                'order-numbering',
                'delivery-fees',
                'product-groups',
                'order-rules',
                'ordering-style',
                //'customer-templates',
                'customers',
                'measurements',
                'courier-journeys'
            ]);

            //make opinionated settings changes

            nextFunc();
        },
        pickupOnly() {
            this.settings.data.courieringForSelf = false;
            this.settings.data.courieringForOthers = false;
            this.settings.data.courieringByOthers = false;
        },
        nextOrdering(nextFunc) {
            if (this.settings.data.orderingAndInvoicingOnly) {
                this.createPlan();
            }
            else {
                this.insertNext('order-fulfilling-questions');
            }

            nextFunc();
        },
        nextFulfilling(nextFunc) {
            this.insertNext('couriering-questions');

            nextFunc();
        },
        async nextCouriering(nextFunc) {
            this.createPlan();

            nextFunc();
        },
        createPlan() {
            var d = this.settings.data;

            if (d.orderingAndInvoicingOnly || d.orderingAndFulfilling) {
                this.insertLast([
                    'products', 
                    'product-categories', 
                    'supplier-pricing',
                    'supplier-payment-terms',
                    'order-numbering', 
                    'invoice-numbering-matches-orders',
                    'email-new-orders-to-customer',
                    'delivery-fees',
                    'product-groups',
                    'order-rules',
                    'ordering-style',
                    'order-pipeline',
                    'order-approval'
                ]);
            }

            if (d.orderFulfillingForSelf || d.orderFulfillingByOthers) {
                this.insertLast(['order-pipeline', 'order-fulfill']);
            }

            if (d.orderFulfillingByOthers) {
                this.insertLast('fulfiller-services');
            }

            if (d.orderFulfillingForSelf || d.orderFulfillingByOthers) {
                this.insertLast('fulfiller-pointers');
            }

            if (d.orderFulfillingForSelf || d.orderFulfillingForOthers) {
                this.insertLast([
                    'order-process',
                    'order-stock-consign',
                    'supply-pointers',
                    'order-package-consign',
                    'order-ready-for-courier',
                    'order-request-courier'
                ]);
            }

            if (d.courieringByOthers) {
                this.insertLast('couriers');
            }

            if (d.courieringForSelf || d.courieringByOthers) {
                this.insertLast('courier-pointers');
            }

            if (d.orderingAndInvoicingOnly || d.orderingAndFulfilling) {
                this.insertLast([
                    'customer-templates',
                    'customers'
                ]);
            }

            if (d.orderingAndFulfilling) {
                this.insertLast('measurements');
            }

            //only self-couriering
            if (d.courieringForSelf &&
                !d.courieringForOthers) {
                //request whether to add courier pricing, etc.
                this.insertLast('courier-pricing-question')
                //if yes, will add courier-pricing, courier-payment-terms, zones
            }
            else if (d.courieringForOthers) {
                this.insertLast([
                    'zones',
                    'courier-pricing',
                    'courier-payment-terms'
                ]);
            }

            //self-couriering
            if (d.courieringForSelf ||
                d.courieringForOthers) {
                this.insertLast([
                    'courier-pipeline',
                    'courier-order-approval',
                    'courier-order-plan',
                    'courier-routes',
                    'courier-order-move',
                    'courier-order-assign',
                    'courier-journeys',
                ]);
            }

            if (d.orderFulfillingForOthers ||
                d.courieringForOthers) {
                this.insertLast([
                    'client-templates',
                    'clients'
                ]);
            }
            
            //couriering
            // if (d.pickupOnly) {
            //     //no pricing and stuff
            // }
            // else {

            // }

            // if (this.settings.data.courieringForSelf ||
            //     this.settings.data.courieringForOthers) {
            //     this.insertLast([
            //         //add pricing, etc.
            //         'auto-journey-assigning',
            //         'journeys',
            //     ]);
            // }

            // if (this.settings.data.courieringByOthers) {
            //     this.insertLast([
            //         'couriers'
            //     ]);
            // }
        },
        nextCourierPricing(nextFunc) {
            


            nextFunc();
        },
        async nextPackageConsign(nextFunc) {
            var courierSettings = await this.$BlitzIt.store.get('picking-settings', null, null, false);

            if (courierSettings != null) {
                if (courierSettings.calculateConsignmentPackages) {
                    this.insertNext('select-consignment-packages');
                    this.insertNext('measurements', true);
                }
                else {
                    this.removeIfNext('measurements');
                    this.removeIfNext('select-consignment-packages');
                }
            }

            nextFunc();
        },
        // async nextRoutePlan(nextFunc) {
        //     var courierSettings = await this.$BlitzIt.store.get('courier-settings', null, null, false);

        //     if (courierSettings != null) {
        //         if (courierSettings.)
        //     }
        // },
        insertAfter(firstStep, stepName) {
            var d = this.settings.data.stepperPlan;
            if (Array.isArray(stepName)) {
                stepName.forEach(sName => {
                    //replace to after said step if said step exists
                    var sInd = d.findIndex(x => x == firstStep);
                    if (sInd >= 0) {
                        var ind = d.findIndex(x => x == sName);
                        if (ind >= 0) {
                            d.splice(ind, 1);
                        }

                        d.splice(sInd, 1, sName);
                    }
                });
            }
            else {
                //replace to after said step if said step exists
                var sInd = d.findIndex(x => x == firstStep);
                if (sInd >= 0) {
                    var ind = d.findIndex(x => x == stepName);
                    if (ind >= 0) {
                        d.splice(ind, 1);
                    }

                    d.splice(sInd, 1, stepName);
                }
            }
        },
        insertLast(stepName) {
            if (Array.isArray(stepName)) {
                stepName.forEach(sName => {
                    if (!this.settings.data.stepperPlan.some(x => x == sName)) {
                        this.settings.data.stepperPlan.push(sName);
                    }
                });
            }
            else {
                if (!this.settings.data.stepperPlan.some(x => x == stepName)) {
                    this.settings.data.stepperPlan.push(stepName);
                }
            }
        },
        insertNext(stepName, allowDoubles = false) {
            if (allowDoubles || !this.settings.data.stepperPlan.some(x => x == stepName)) {
                this.settings.data.stepperPlan.splice(this.settings.data.currentIndex + 1, 0, stepName);
            }
        },
        removeIfNext(stepName) {
            var d = this.settings.data;
            var nextInd = d.currentIndex + 1;
            if (nextInd < d.stepperPlan.length) {
                if (d.stepperPlan[nextInd] == stepName) {
                    d.stepperPlan.splice(nextInd, 1);
                }
            }
        },
        next(nextFunc, nextSteps) {
            if (this.isLengthyArray(nextSteps)) {
                nextSteps.forEach(step => {
                    this.insertNext(step);
                });
            }

            nextFunc();
        },
        async updateOnlineNext(nextFunc, nav, data, nextSteps) {
            if (this.isLengthyArray(nextSteps)) {
                nextSteps.forEach(step => {
                    this.insertNext(step);
                });
            }

            if (nav != null && data != null) {
                await this.updateOnlineData(nav, data);
            }

            if (nextFunc != null) {
                nextFunc();
            }
        },
        back() {
            if (this.isLengthyArray(this.settings.history)) {
                this.settings.data = this.settings.history[0];
                this.settings.history.splice(0, 1);
            }
            
            this.saveLocally(this.dataKey, this.settings.data);
            this.saveLocally(this.historyKey, this.settings.history);
        },
        restart(navToSetup = true) {
            this.settings = {
                data: {
                    currentIndex: 0,
                    currentStep: 'start',
                    stepperPlan: ['start', 'locations', 'ordering-questions'],
                    showMore: false,
                },
                history: []
            };

            this.saveLocally(this.dataKey, this.settings.data);
            this.saveLocally(this.historyKey, this.settings.history);

            if (navToSetup) {
                this.$router.push({ name: 'setup' });
            }
        },
        async updateOnlineData(nav, data) {
            this.loadingMsg = 'Updating Data';
            var oData = await this.$BlitzIt.store.get(nav);
            oData = Object.assign(oData, data);
            await this.$BlitzIt.store.patch(nav, oData);
            this.loadingMsg = null;
        }
    }
}
</script>